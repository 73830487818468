@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'ApercuPro';
  src: url('/fonts/ApercuPro-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}