/* Theme System - Core Variables */
:root {
  // Typography
  --font-primary: var(--theme-font-primary);
  --font-secondary: var(--theme-font-secondary);
  
  // Font weights
  --font-weight-light: var(--theme-font-weight-light);
  --font-weight-regular: var(--theme-font-weight-regular);
  --font-weight-medium: var(--theme-font-weight-medium);
  --font-weight-semibold: var(--theme-font-weight-semibold);
  --font-weight-bold: var(--theme-font-weight-bold);
  
  // Line heights
  --leading-none: var(--theme-leading-none);
  --leading-tight: var(--theme-leading-tight);
  --leading-snug: var(--theme-leading-snug);
  --leading-normal: var(--theme-leading-normal);
  --leading-relaxed: var(--theme-leading-relaxed);
  --leading-loose: var(--theme-leading-loose);
  
  // Colors
  --color-primary: var(--theme-color-primary);
  --color-secondary: var(--theme-color-secondary);
  --color-background: var(--theme-color-background);
  --color-text-primary: var(--theme-color-text-primary);
  --color-text-secondary: var(--theme-color-text-secondary);
  --color-button-primary: var(--theme-color-button-primary);
  --color-button-secondary: var(--theme-color-button-secondary);
  
  // Accent colors
  --color-accent-purple: var(--theme-color-accent-purple);
  --color-accent-yellow: var(--theme-color-accent-yellow);
  --color-accent-pink: var(--theme-color-accent-pink);
  --color-accent-gray-light: var(--theme-color-accent-gray-light);
  --color-accent-gray-dark: var(--theme-color-accent-gray-dark);
} 