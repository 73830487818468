/* Typography Utilities */
.text-primary {
  font-family: var(--font-primary);
}

.text-secondary {
  font-family: var(--font-secondary);
}

/* Font Weight Utilities */
.font-light { font-weight: var(--font-weight-light); }
.font-regular { font-weight: var(--font-weight-regular); }
.font-medium { font-weight: var(--font-weight-medium); }
.font-semibold { font-weight: var(--font-weight-semibold); }
.font-bold { font-weight: var(--font-weight-bold); }

/* Line Height Utilities */
.leading-none { line-height: var(--leading-none); }
.leading-tight { line-height: var(--leading-tight); }
.leading-snug { line-height: var(--leading-snug); }
.leading-normal { line-height: var(--leading-normal); }
.leading-relaxed { line-height: var(--leading-relaxed); }
.leading-loose { line-height: var(--leading-loose); }

/* Color Utilities */
.text-color-primary { color: var(--color-text-primary); }
.text-color-secondary { color: var(--color-text-secondary); }
.bg-primary { background-color: var(--color-primary); }
.bg-secondary { background-color: var(--color-secondary); }
.bg-background { background-color: var(--color-background); }

/* Button Base Styles */
.button {
  &--primary {
    background-color: var(--color-button-primary);
    color: var(--color-text-primary);
  }
  
  &--secondary {
    background-color: var(--color-button-secondary);
    color: var(--color-text-secondary);
  }
} 