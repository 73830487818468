@use "@angular/material" as mat;
@use "./theme-colors" as theme;
@use "./variables";
@use "./utilities";
@use "./fonts";

/* Material Core */
@include mat.core();

:root {
  @include mat.theme(
    (
      color: (
        theme-type: light,
        primary: theme.$primary-palette,
      ),
      typography: var(--font-primary),
      density: 0,
    )
  );
}

body {
  font-family: var(--font-primary);
  margin: 0;
  padding: 0;
  line-height: var(--leading-normal);
}
